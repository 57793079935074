import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import SocialProfile from '../../../components/social-profile/social-profile';
import { StaticImage } from "gatsby-plugin-image"
import {
  IntroWrapper,
  IntroImage,
  IntroTitle,
  Desciption,
  IntroInfo,
} from './style';
import {
  IoLogoInstagram,
  IoLogoGithub,
  IoLogoLinkedin,
} from 'react-icons/io';

type IntroProps = {};


const Intro: React.FunctionComponent<IntroProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          about
          instagramProfile
          linkedinProfile
          githubProfile
        }
      }
    }
  `);

  const { author, about, instagramProfile, linkedinProfile, githubProfile} = Data.site.siteMetadata;

  const SocialLinks = [
    {
      icon: <IoLogoLinkedin />,
      url: `https://linkedin.com/in/${linkedinProfile}`,
      tooltip: `Linkedin: ${linkedinProfile}`,
    },
    {
      icon: <IoLogoGithub />,
      url: `https://github.com/${githubProfile}`,
      tooltip: `Github: ${githubProfile}`,
    },
    {
      icon: <IoLogoInstagram />,
      url: `https://instagram.com/${instagramProfile}`,
      tooltip: `Instagram: @${instagramProfile}`,
    }
  ];

  return (
    <IntroWrapper>
      <IntroImage>
        <StaticImage src='../../../images/author.jpg' alt="author" />
      </IntroImage>
      <IntroInfo>
        <IntroTitle>
          Hey! Eu sou o <b>{author}</b>
        </IntroTitle>
        <Desciption>{about}</Desciption>
        <SocialProfile items={SocialLinks} />
      </IntroInfo>
    </IntroWrapper>
  );
};

export default Intro;
